.Header {
    background-color: rgb(15, 81, 138);
    height: 7vh;
    width: 100vw;
    color: rgb(252, 243, 226);
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
  }
  
  .Logo {
    margin-left: 2vw;
  }
  
  img {
    height: 6vh;
  }
  
  .Name {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    font-size: 1.7vh;
  }
  
  .Buttons {
    display: flex;
    justify-content: flex-end;
    margin-left: 2vw;
    cursor: pointer; /* Add this line */
    
  }
  
  .Button {
    font-size: 1.2vw;
    color: rgb(255, 137, 67)
  }
  .headerWeb {
    display: block;
  }
  .headerMobile {
    display: none;
  }
  
  /* Media Queries for Mobile */
  @media only screen and (max-width: 768px) {
    .Header {
      height: 7vh; /* Increase height for better visibility */
      flex-direction: row; /* Align items horizontally */
      width: 100vw;
      justify-content: space-between;
    }

    .LogoPic {
        height: 4vh; /* Adjust logo height */
    }
  
    .Name {
      font-size: 2vw; /* Increase font size for name */
    }
  
  
    .Button {
      font-size: 2vw; /* Adjust font size for buttons */
      margin-right: 2vw; /* Adjust margin between buttons */
      width: 5vh;
      display: none;
    }

    .headerWeb {
        display: none;
      }
      .headerMobile {
        display: block;
      }

  }